<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="6"
      class="leftPanel align-center"
      :class="{ 'full-page': $vuetify.breakpoint.mdAndUp }"
    >
      <v-row class="ma-16">
        <v-col cols="2"></v-col>
        <v-col cols="8">
          <v-img
            :src="
              require(`@/assets/core/just_logo_${
                $vuetify.theme.dark ? 'light3' : 'dark3'
              }.png`)
            "
            max-height="1200px"
          />
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <h2 class="textHeaderLeftPanel--text">Welkom bij Grant Thornton</h2>
      </v-row>
      <v-row class="justify-center mt-10">
        <h3 class="textHeaderLeftPanel--text">
          Liever een ander kleurschema klik op de knop
        </h3></v-row
      >
      <v-row class="justify-center mt-10">
        <div class="text-center">
          <v-btn class="secondary mb-10" @click="toggle_dark_mode">
            <v-icon left>mdi-theme-light-dark</v-icon>
            <span>Donker/Licht</span>
          </v-btn>
        </div>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="2" />
        <v-col cols="8">
          <h3 class="textHeaderLeftPanel--text">Email</h3>
          <br />
          <h4 class="textLeftPanel--text">
            Met uw email adres komt u in uw persoonlijke omgeving van de
            familiestatuut app.<br />
            Na het invullen van uw email adres krijgt u uw eenmalige code per
            mail toegestuurd.
            <br />
            Controleer eventueel uw spambox.
          </h4>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6" class="justify-center align-center">
      <div class="ma-5 pa-0">
        <v-row class="ml-15 mr-15 mt-15">
          <v-col cols="12" class="mt-15 mb-15"></v-col>
          <v-col cols="12" class="mt-15 mb-15"></v-col>
          <v-col cols="12">
            <ValidationObserver
              ref="obs"
              v-slot="{ invalid, validated, handleSubmit }"
            >
              <v-form ref="form">
                <BaseValInput
                  rules="required|email"
                  v-model.trim="email"
                  label="email"
                  @keyup="lowercase"
                />

                <div class="text-end mr-15">
                  <v-btn
                    elevation="15"
                    class="accent white--text"
                    @click="handleSubmit(login)"
                    :disabled="invalid || !validated"
                  >
                    Verder</v-btn
                  >
                </div>
              </v-form>
            </ValidationObserver>
          </v-col>
        </v-row>
        <flash-message :error="error" v-if="error" key="error" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// import { mapGetters } from "vuex";
// import { getError } from "@/utils/helpers";
import FlashMessage from "@/components/FlashMessage";
import { getError } from "@/utils/helpers";
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import BaseValInput from "@/components/input/BaseValInput";
export default {
  name: "Login",
  components: { FlashMessage, ValidationObserver, BaseValInput },
  data() {
    return {
      error: null,
      valid: false,
      theme: null,
      email: null,
      emailRules: [
        (v) => !!v || "E-mail is verplicht",
        // eslint-disable-next-line no-useless-escape
        (v) =>
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v
          ) || "Dit lijkt geen goed email adres",
      ],
    };
  },
  methods: {
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    lowercase() {
      this.email = this.email.toLowerCase();
    },
    async login() {
      let data = {
        email: this.email,
      };
      this.error = null;
      // axios.post("/loginchart", data, {
      //   headers: {
      //   },
      // });

      try {
        await this.$store.dispatch("auth/loginA", data);
        if (this.stepOne) {
          this.$store.commit("auth/SET_EMAIL", this.email);
          this.$router.push({ name: "LoginB" });
          console.log("stap1 af");
        } else {
          console.log("error");
          const error = Error("Dit email adres lijkt niet geheel te kloppen");
          error.name = "Fetch User";
          throw error;
        }
      } catch (error) {
        this.error = getError(error);
      }
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      this.$vuetify.theme.dark = theme === "true";
    }
  },
  computed: {
    ...mapGetters("auth", ["stepOne"]),
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}

.height-col {
  height: 100vh;
}
</style>
